/// Bootstrap overrides
$spacer: 1rem;
$spacers: (
    0: 0,
    1: $spacer * .25,  // 4px
    2: $spacer * .5,   // 8px
    3: $spacer,        // 16px
    4: $spacer * 1.5,  // 24px
    5: $spacer * 2,    // 32px
    6: $spacer * 3,    // 48px
    7: $spacer * 4,    // 64px
    8: $spacer * 5,    // 80px
    9: $spacer * 7.5,  // 120px
    10: $spacer * 10   // 160px
);

$sizes: (
    25: 25%,
    33: 33.333333%,
    50: 50%,
    66: 66.666667%,
    75: 75%,
    100: 100%,
    auto: auto
);

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px
);

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    // xxl: 1536px
    xxl: 1600px
    // xxl: 1920px
);

$container-padding-sizes: (
    xs: 24px,
    lg: 32px,
    xl: 48px,
    xxl: 64px
);

// $grid-columns: 12;
$grid-gutter-width: 24px;
// $grid-row-columns: 6;
$gutters: (
    0: 0,
    1: $spacer * .25, // 4px
    2: $spacer * .5,  // 8px
    3: $spacer,       // 16px
    4: $spacer * 1.5, // 24px
    5: $spacer * 2,   // 32px
    6: $spacer * 3,   // 48px
    7: $spacer * 4    // 64px
);

/// Z-Index
$z-index__header: 100;
$z-index__off-canvas: 200;

// Colors
$color__primary: #2A3A4A;
$color__primary--font: color-contrast($color__primary);
$color__primary--hover: #307CB3;
$color__primary--disabled: #96BBD5;
$color__secondary: $color__primary;
$color__success: #28a745;
$color__danger: #dc3545;
$color__danger--font: color-contrast($color__danger);
// $color__note: #ffc107;
$color__note: #efef00;
$color__note--font: color-contrast($color__note);
$color__gray: #959494;
$color__gray--light: #F5F8EE;
$color__black: #000000;
$color__white: #ffffff;
$color__font: #1e1e1e;
$color__font--negative: $color__white;
$color__link: $color__primary;
$color__link--hover: $color__primary--hover;
$color__placeholder: $color__gray;
$color__selection--foreground: $color__white;
$color__selection--background: $color__primary;
$color__breaking: $color__danger;
$color__breaking--font: color-contrast($color__breaking);
$color__breaking--hover: $color__font;
$color__breaking--hover-font: color-contrast($color__breaking--hover);
$color__ccm: $color__primary;
$color__ccm--font: $color__primary--font;

// Filters
// https://isotropic.co/tool/hex-color-to-css-filter/
$filter__white: brightness(0) invert(1);
$filter__primary: invert(19%) sepia(11%) saturate(1748%) hue-rotate(169deg) brightness(94%) contrast(88%);
$filter__primary--hover:  invert(45%) sepia(31%) saturate(1125%) hue-rotate(163deg) brightness(89%) contrast(85%);
$filter__primary--disabled: invert(62%) sepia(6%) saturate(711%) hue-rotate(171deg) brightness(99%) contrast(91%);;
$filter__secondary: $filter__primary;
$filter__danger: invert(31%) sepia(26%) saturate(5435%) hue-rotate(333deg) brightness(89%) contrast(93%);
$filter__note: invert(85%) sepia(54%) saturate(4380%) hue-rotate(351deg) brightness(100%) contrast(104%);
$filter__gray: invert(60%) sepia(5%) saturate(47%) hue-rotate(22deg) brightness(98%) contrast(86%);
$filter__gray--light: invert(99%) sepia(19%) saturate(573%) hue-rotate(176deg) brightness(99%) contrast(95%);
$filter__font: invert(9%) sepia(0%) saturate(0%) hue-rotate(181deg) brightness(93%) contrast(91%);
$filter__breaking--icon: filter-contrast($color__breaking);
$filter__breaking--hover-icon: filter-contrast($color__breaking--hover);

// Hover/focus animations
$animations: true; // true, false

// Buttons
$button__type: rounded; // edged, rounded
$button__style: filled; // filled, outlined, raised

// Base border radius (input fields, tiles, ...)
$border__radius: 15px;

// Border styles
$border__color: $color__gray;
$border__style: solid;
$border__width: 1px;

// Font weights
$font__weight--normal: 400;
$font__weight--bold: 800;

// Font families
$font__family--sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font__family--monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
//$font__family--system: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
//$font__family--system: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
// $font__family--system: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", Helvetica, Arial, sans-serif;
$font__family--system: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font__family--base: "Albert Sans", #{$font__family--system};
$font__family--heading: $font__family--base;
// $font__family--heading: $headings-font-family;
$font__family--mono: monospace;

// Line height
$font__line-height: 1.5;

// Margins
$margin__block: 32px;
$margin__block--headings: 48px;
$margin__headings--h1: 48px;
$margin__headings--h2: 16px;
$margin__headings--h3: 8px;

/// Main navigation
$main-navigation__mode: off-canvas; // menu or off-canvas

/// Header
$header--sticky: true;
$header__height--mobile: 80px;
$header__height--unpinned: 100px;
$header__height: 120px;
@if $main-navigation__mode == menu {
    $header__height--unpinned: 160px;
    $header__height: 200px;
}
$header__background-color: $color__white;
$header__box-shadow: 0 2px 10px rgba($color__black, .12);

// Logo
$logo__height--mobile: 32px;
$logo__height--unpinned: 52px;
$logo__height: 52px;
@if $main-navigation__mode == menu {
    $logo__height--unpinned: 48px;
    $logo__height: 48px;
}

// Breakpoints
$breakpoint__desktop: map-get($grid-breakpoints, lg);
$breakpoint__big-desktop: map-get($grid-breakpoints, xxl);

/// Off canvas
$off-canvas__desktop: false; // show mobile off canvas on desktop
$off-canvas__breakpoint: map-get($grid-breakpoints, lg);
$off-canvas__max-width: map-get($grid-breakpoints, sm);
$off-canvas__align: right; //left or right
$off-canvas__padding-y: 48px;
/// @todo Variable Padding-Größen
// $off-canvas__padding-y: (
//     0: 16px,
//     576px: 32px
// );
$off-canvas__background: $color__white;
$off-canvas__toggle-size: $header__height;
$off-canvas__toggle-size--unpinned: $header__height--unpinned;
$off-canvas__toggle-size--mobile: $header__height--mobile;
$off-canvas__toggle-color: $color__white;
$off-canvas__toggle-color--active: $color__primary;
$off-canvas__toggle-color--hover: $color__white;
$off-canvas__toggle-background-color: $color__primary;
$off-canvas__toggle-background-color--active: transparent;
$off-canvas__toggle-background-color--hover: $color__primary--hover;
// $off-canvas__off: rgba(0, 0, 0, 0.5);
$off-canvas__off: rgba(65, 65, 65, .65);
//$off-canvas__overlay: rgba(0, 0, 0, 0.9);
$off-canvas__overlay: $color__white;

/// Bootstrap overrides
$primary: $color__primary;
$secondary: $color__secondary;
$success: $color__success;
$info: $color__note;
$danger: $color__danger;
$body-color: $color__font;
$body-bg: $color__white;
$font-family-base: $font__family--base;
$font-weight-base: $font__weight--normal;
$line-height-base: $font__line-height;
// $headings-font-family: $font__family--heading;
$headings-font-weight: $font__weight--bold;
// $headings-line-height: $font__line-height;
